import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
}

const Gallery = ({ posts }) => {
  return (
    <ul className="gallery-grid">
      {posts.edges.map(
        item =>
          item.node.localImage && (
            <li>
              <Link
                to={`/${item.node.id.split(/-/)[0]}/${slugify(
                  item.node.caption
                )}/`}
                key={item.node.id}
                title={item.node.caption}
              >
                <GatsbyImage
                  image={item.node.localImage.childImageSharp.gatsbyImageData}
                  alt={item.node.caption || "Instagram Post"}
                />
              </Link>
            </li>
          )
      )}
    </ul>
  )
}

export default Gallery
